import React from "react"

const HomeProjects = props => (

<div className="container">
  <div className="row">
    <div className="col-md-4 second-div">
      <div className="card" style={{width: '18rem'}}>
        <img className="card-img-top" src="/images/covid19.jpg" alt="COVID-19" />
        <div className="card-body">
          <h5 className="card-title">COVID-19 Dashbaord</h5>
          <p className="card-text">Summary of Daily, Weekly, & Monthly reported cases.</p>

          <a href="/COVID19" class="btn btn-primary">Go to Project</a>
        </div>
      </div>
    </div>
  </div>
</div>

);

export default HomeProjects;