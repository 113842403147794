import React from "react";


import Header from "./Header";
import HomeProjects from "./HomeProjects";

class MainIndex extends React.Component{

    render(){
        return (
            <div>
            
            <Header/>
            <HomeProjects/>

            </div>
        )
    }

}


export default MainIndex;