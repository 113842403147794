import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import MainIndex from "./MainIndex";
import COVID19_Index from "./COVID19/COVID19_Index";
import NotFound from "./404";



const Router = () => (
    <BrowserRouter>

        <Switch>
            <Route exact path = "/" component={MainIndex} />
            <Route path = "/COVID-19" component={COVID19_Index} />
            <Route path = "/COVID19" component={COVID19_Index} />
            <Route path = "/covid19" component={COVID19_Index} /> 
            <Route component={NotFound} />
        </Switch>
    </BrowserRouter>
);


export default Router;
