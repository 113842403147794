const covid_data_raw = [{'country': 'Afghanistan',
'latitude': 33.93911,
'longitude': 67.709953,
'total_confirmed': 173659.0,
'c_last_24hours': 264.0,
'c_last_7days': 943.0,
'c_last_30days': 11548.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 7598.0,
'd_last_24hours': 5.0,
'd_last_7days': 29.0,
'd_last_30days': 190.0},
{'country': 'Albania',
'latitude': 41.1533,
'longitude': 20.1683,
'total_confirmed': 271563.0,
'c_last_24hours': 36.0,
'c_last_7days': 1108.0,
'c_last_30days': 15822.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 3469.0,
'd_last_24hours': 5.0,
'd_last_7days': 18.0,
'd_last_30days': 135.0},
{'country': 'Algeria',
'latitude': 28.0339,
'longitude': 1.6596,
'total_confirmed': 264936.0,
'c_last_24hours': 81.0,
'c_last_7days': 571.0,
'c_last_30days': 14162.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 6835.0,
'd_last_24hours': 4.0,
'd_last_7days': 23.0,
'd_last_30days': 269.0},
{'country': 'Andorra',
'latitude': 42.5063,
'longitude': 1.5218,
'total_confirmed': 37999.0,
'c_last_24hours': 0.0,
'c_last_7days': 179.0,
'c_last_30days': 2443.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 151.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 6.0},
{'country': 'Angola',
'latitude': -11.2027,
'longitude': 17.8739,
'total_confirmed': 98741.0,
'c_last_24hours': 40.0,
'c_last_7days': 70.0,
'c_last_30days': 665.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1900.0,
'd_last_24hours': 1.0,
'd_last_7days': 1.0,
'd_last_30days': 5.0},
{'country': 'Antarctica',
'latitude': -71.9499,
'longitude': 23.346999999999998,
'total_confirmed': 11.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 0.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 0.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Antigua and Barbuda',
'latitude': 17.0608,
'longitude': -61.7964,
'total_confirmed': 7437.0,
'c_last_24hours': 0.0,
'c_last_7days': 29.0,
'c_last_30days': 879.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 135.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 8.0},
{'country': 'Argentina',
'latitude': -38.4161,
'longitude': -63.6167,
'total_confirmed': 8900656.0,
'c_last_24hours': 3478.0,
'c_last_7days': 45032.0,
'c_last_30days': 565472.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 126152.0,
'd_last_24hours': 32.0,
'd_last_7days': 534.0,
'd_last_30days': 5164.0},
{'country': 'Armenia',
'latitude': 40.0691,
'longitude': 45.0382,
'total_confirmed': 419832.0,
'c_last_24hours': 139.0,
'c_last_7days': 3322.0,
'c_last_30days': 53399.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 8461.0,
'd_last_24hours': 12.0,
'd_last_7days': 107.0,
'd_last_30days': 415.0},
{'country': 'Australia',
'latitude': -31.9505,
'longitude': 115.8605,
'total_confirmed': 3235017.0,
'c_last_24hours': 25418.0,
'c_last_7days': 135768.0,
'c_last_30days': 627775.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 5210.0,
'd_last_24hours': 39.0,
'd_last_7days': 185.0,
'd_last_30days': 1450.0},
{'country': 'Austria',
'latitude': 47.5162,
'longitude': 14.5501,
'total_confirmed': 2679742.0,
'c_last_24hours': 20210.0,
'c_last_7days': 163780.0,
'c_last_30days': 851575.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 14809.0,
'd_last_24hours': 22.0,
'd_last_7days': 178.0,
'd_last_30days': 718.0},
{'country': 'Azerbaijan',
'latitude': 40.1431,
'longitude': 47.5769,
'total_confirmed': 785670.0,
'c_last_24hours': 369.0,
'c_last_7days': 7866.0,
'c_last_30days': 129386.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 9417.0,
'd_last_24hours': 17.0,
'd_last_7days': 137.0,
'd_last_30days': 697.0},
{'country': 'Bahamas',
'latitude': 25.025885,
'longitude': -78.035889,
'total_confirmed': 33135.0,
'c_last_24hours': 6.0,
'c_last_7days': 54.0,
'c_last_30days': 623.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 771.0,
'd_last_24hours': 0.0,
'd_last_7days': 1.0,
'd_last_30days': 25.0},
{'country': 'Bahrain',
'latitude': 26.0275,
'longitude': 50.55,
'total_confirmed': 514883.0,
'c_last_24hours': 2577.0,
'c_last_7days': 16246.0,
'c_last_30days': 146116.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1454.0,
'd_last_24hours': 1.0,
'd_last_7days': 12.0,
'd_last_30days': 47.0},
{'country': 'Bangladesh',
'latitude': 23.685,
'longitude': 90.3563,
'total_confirmed': 1943577.0,
'c_last_24hours': 897.0,
'c_last_7days': 6740.0,
'c_last_30days': 158245.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 29037.0,
'd_last_24hours': 4.0,
'd_last_7days': 47.0,
'd_last_30days': 674.0},
{'country': 'Barbados',
'latitude': 13.1939,
'longitude': -59.5432,
'total_confirmed': 55165.0,
'c_last_24hours': 134.0,
'c_last_7days': 1059.0,
'c_last_30days': 11172.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 316.0,
'd_last_24hours': 1.0,
'd_last_7days': 5.0,
'd_last_30days': 37.0},
{'country': 'Belarus',
'latitude': 53.7098,
'longitude': 27.9534,
'total_confirmed': 919736.0,
'c_last_24hours': 2268.0,
'c_last_7days': 29647.0,
'c_last_30days': 178748.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 6480.0,
'd_last_24hours': 10.0,
'd_last_7days': 88.0,
'd_last_30days': 441.0},
{'country': 'Belgium',
'latitude': 50.8333,
'longitude': 4.469936,
'total_confirmed': 3557314.0,
'c_last_24hours': 13280.0,
'c_last_7days': 36746.0,
'c_last_30days': 501389.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 30179.0,
'd_last_24hours': 58.0,
'd_last_7days': 139.0,
'd_last_30days': 1222.0},
{'country': 'Belize',
'latitude': 17.1899,
'longitude': -88.4976,
'total_confirmed': 56694.0,
'c_last_24hours': 97.0,
'c_last_7days': 320.0,
'c_last_30days': 6207.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 650.0,
'd_last_24hours': 0.0,
'd_last_7days': 3.0,
'd_last_30days': 25.0},
{'country': 'Benin',
'latitude': 9.3077,
'longitude': 2.3158,
'total_confirmed': 26567.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 117.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 163.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Bhutan',
'latitude': 27.5142,
'longitude': 90.4336,
'total_confirmed': 12702.0,
'c_last_24hours': 508.0,
'c_last_7days': 2506.0,
'c_last_30days': 8017.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 6.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 2.0},
{'country': 'Bolivia',
'latitude': -16.2902,
'longitude': -63.5887,
'total_confirmed': 893395.0,
'c_last_24hours': 172.0,
'c_last_7days': 2283.0,
'c_last_30days': 41115.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 21438.0,
'd_last_24hours': 12.0,
'd_last_7days': 38.0,
'd_last_30days': 519.0},
{'country': 'Bosnia and Herzegovina',
'latitude': 43.9159,
'longitude': 17.6791,
'total_confirmed': 371202.0,
'c_last_24hours': 550.0,
'c_last_7days': 1972.0,
'c_last_30days': 27216.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 15459.0,
'd_last_24hours': 48.0,
'd_last_7days': 97.0,
'd_last_30days': 1149.0},
{'country': 'Botswana',
'latitude': -22.3285,
'longitude': 24.6849,
'total_confirmed': 263950.0,
'c_last_24hours': 0.0,
'c_last_7days': 1298.0,
'c_last_30days': 13204.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 2619.0,
'd_last_24hours': 0.0,
'd_last_7days': 5.0,
'd_last_30days': 39.0},
{'country': 'Brazil',
'latitude': -14.235,
'longitude': -51.9253,
'total_confirmed': 28796571.0,
'c_last_24hours': 19777.0,
'c_last_7days': 434620.0,
'c_last_30days': 3435924.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 649676.0,
'd_last_24hours': 239.0,
'd_last_7days': 3941.0,
'd_last_30days': 22526.0},
{'country': 'Brunei',
'latitude': 4.5353,
'longitude': 114.7277,
'total_confirmed': 63542.0,
'c_last_24hours': 4095.0,
'c_last_7days': 22493.0,
'c_last_30days': 47197.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 125.0,
'd_last_24hours': 4.0,
'd_last_7days': 13.0,
'd_last_30days': 27.0},
{'country': 'Bulgaria',
'latitude': 42.7339,
'longitude': 25.4858,
'total_confirmed': 1091279.0,
'c_last_24hours': 2759.0,
'c_last_7days': 14119.0,
'c_last_30days': 149465.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 35581.0,
'd_last_24hours': 99.0,
'd_last_7days': 336.0,
'd_last_30days': 2421.0},
{'country': 'Burkina Faso',
'latitude': 12.2383,
'longitude': -1.5616,
'total_confirmed': 20751.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 140.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 375.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 9.0},
{'country': 'Burma',
'latitude': 21.9162,
'longitude': 95.956,
'total_confirmed': 587891.0,
'c_last_24hours': 1693.0,
'c_last_7days': 15764.0,
'c_last_30days': 52637.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 19368.0,
'd_last_24hours': 3.0,
'd_last_7days': 24.0,
'd_last_30days': 58.0},
{'country': 'Burundi',
'latitude': -3.3731,
'longitude': 29.9189,
'total_confirmed': 38127.0,
'c_last_24hours': 64.0,
'c_last_7days': 109.0,
'c_last_30days': 828.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 38.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Cabo Verde',
'latitude': 16.5388,
'longitude': -23.0418,
'total_confirmed': 55886.0,
'c_last_24hours': 4.0,
'c_last_7days': 22.0,
'c_last_30days': 209.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 401.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 6.0},
{'country': 'Cambodia',
'latitude': 11.55,
'longitude': 104.9167,
'total_confirmed': 130276.0,
'c_last_24hours': 356.0,
'c_last_7days': 2701.0,
'c_last_30days': 8977.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 3032.0,
'd_last_24hours': 0.0,
'd_last_7days': 10.0,
'd_last_30days': 17.0},
{'country': 'Cameroon',
'latitude': 3.8480000000000003,
'longitude': 11.5021,
'total_confirmed': 119240.0,
'c_last_24hours': 0.0,
'c_last_7days': 133.0,
'c_last_30days': 5127.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1923.0,
'd_last_24hours': 0.0,
'd_last_7days': 3.0,
'd_last_30days': 56.0},
{'country': 'Canada',
'latitude': 64.2823,
'longitude': -135.0,
'total_confirmed': 3291827.0,
'c_last_24hours': 8449.0,
'c_last_7days': 39316.0,
'c_last_30days': 258003.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 36594.0,
'd_last_24hours': 58.0,
'd_last_7days': 435.0,
'd_last_30days': 2872.0},
{'country': 'Central African Republic',
'latitude': 6.6111,
'longitude': 20.9394,
'total_confirmed': 14225.0,
'c_last_24hours': 38.0,
'c_last_7days': 38.0,
'c_last_30days': 327.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 113.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 3.0},
{'country': 'Chad',
'latitude': 15.4542,
'longitude': 18.7322,
'total_confirmed': 7255.0,
'c_last_24hours': 7.0,
'c_last_7days': 7.0,
'c_last_30days': 180.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 190.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Chile',
'latitude': -35.6751,
'longitude': -71.543,
'total_confirmed': 3061019.0,
'c_last_24hours': 19619.0,
'c_last_7days': 165088.0,
'c_last_30days': 923276.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 42353.0,
'd_last_24hours': 153.0,
'd_last_7days': 827.0,
'd_last_30days': 2669.0},
{'country': 'China',
'latitude': 29.1832,
'longitude': 120.0934,
'total_confirmed': 315387.0,
'c_last_24hours': 34666.0,
'c_last_7days': 140539.0,
'c_last_30days': 195130.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 5380.0,
'd_last_24hours': 85.0,
'd_last_7days': 408.0,
'd_last_30days': 531.0},
{'country': 'Colombia',
'latitude': 4.5709,
'longitude': -74.2973,
'total_confirmed': 6064583.0,
'c_last_24hours': 1882.0,
'c_last_7days': 12457.0,
'c_last_30days': 192606.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 138767.0,
'd_last_24hours': 74.0,
'd_last_7days': 482.0,
'd_last_30days': 4688.0},
{'country': 'Comoros',
'latitude': -11.6455,
'longitude': 43.3333,
'total_confirmed': 8033.0,
'c_last_24hours': 0.0,
'c_last_7days': 9.0,
'c_last_30days': 201.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 160.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Congo (Brazzaville)',
'latitude': -0.228,
'longitude': 15.8277,
'total_confirmed': 24020.0,
'c_last_24hours': 53.0,
'c_last_7days': 95.0,
'c_last_30days': 367.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 378.0,
'd_last_24hours': 0.0,
'd_last_7days': 1.0,
'd_last_30days': 7.0},
{'country': 'Congo (Kinshasa)',
'latitude': -4.0383,
'longitude': 21.7587,
'total_confirmed': 86018.0,
'c_last_24hours': 19.0,
'c_last_7days': 142.0,
'c_last_30days': 1020.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1335.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 57.0},
{'country': 'Costa Rica',
'latitude': 9.7489,
'longitude': -83.7534,
'total_confirmed': 806928.0,
'c_last_24hours': 4456.0,
'c_last_7days': 13093.0,
'c_last_30days': 124448.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 8037.0,
'd_last_24hours': 38.0,
'd_last_7days': 83.0,
'd_last_30days': 504.0},
{'country': 'Cote d Ivoire',
'latitude': 7.54,
'longitude': -5.5471,
'total_confirmed': 81483.0,
'c_last_24hours': 16.0,
'c_last_7days': 86.0,
'c_last_30days': 788.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 793.0,
'd_last_24hours': 1.0,
'd_last_7days': 2.0,
'd_last_30days': 8.0},
{'country': 'Croatia',
'latitude': 45.1,
'longitude': 15.2,
'total_confirmed': 1054794.0,
'c_last_24hours': 198.0,
'c_last_7days': 11460.0,
'c_last_30days': 119072.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 15069.0,
'd_last_24hours': 39.0,
'd_last_7days': 164.0,
'd_last_30days': 1292.0},
{'country': 'Cuba',
'latitude': 21.521757,
'longitude': -77.78116700000001,
'total_confirmed': 1069862.0,
'c_last_24hours': 475.0,
'c_last_7days': 3448.0,
'c_last_30days': 27191.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 8494.0,
'd_last_24hours': 0.0,
'd_last_7days': 3.0,
'd_last_30days': 97.0},
{'country': 'Cyprus',
'latitude': 35.1264,
'longitude': 33.4299,
'total_confirmed': 322516.0,
'c_last_24hours': 2356.0,
'c_last_7days': 11277.0,
'c_last_30days': 69166.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 856.0,
'd_last_24hours': 2.0,
'd_last_7days': 26.0,
'd_last_30days': 125.0},
{'country': 'Czechia',
'latitude': 49.8175,
'longitude': 15.472999999999999,
'total_confirmed': 3574328.0,
'c_last_24hours': 0.0,
'c_last_7days': 50459.0,
'c_last_30days': 584892.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 38592.0,
'd_last_24hours': 0.0,
'd_last_7days': 257.0,
'd_last_30days': 1408.0},
{'country': 'Denmark',
'latitude': 56.2639,
'longitude': 9.5018,
'total_confirmed': 2810800.0,
'c_last_24hours': 16588.0,
'c_last_7days': 129697.0,
'c_last_30days': 1067554.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 4658.0,
'd_last_24hours': 30.0,
'd_last_7days': 246.0,
'd_last_30days': 897.0},
{'country': 'Diamond Princess',
'latitude': 0.0,
'longitude': 0.0,
'total_confirmed': 712.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 0.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 13.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Djibouti',
'latitude': 11.8251,
'longitude': 42.5903,
'total_confirmed': 15547.0,
'c_last_24hours': 0.0,
'c_last_7days': 5.0,
'c_last_30days': 96.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 189.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Dominica',
'latitude': 15.415,
'longitude': -61.371,
'total_confirmed': 11108.0,
'c_last_24hours': 92.0,
'c_last_7days': 155.0,
'c_last_30days': 2076.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 57.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 6.0},
{'country': 'Dominican Republic',
'latitude': 18.7357,
'longitude': -70.1627,
'total_confirmed': 574556.0,
'c_last_24hours': 485.0,
'c_last_7days': 1960.0,
'c_last_30days': 20504.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 4368.0,
'd_last_24hours': 0.0,
'd_last_7days': 8.0,
'd_last_30days': 63.0},
{'country': 'Ecuador',
'latitude': -1.8312,
'longitude': -78.1834,
'total_confirmed': 827760.0,
'c_last_24hours': 0.0,
'c_last_7days': 7219.0,
'c_last_30days': 98000.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 35223.0,
'd_last_24hours': 0.0,
'd_last_7days': 51.0,
'd_last_30days': 709.0},
{'country': 'Egypt',
'latitude': 26.820553000000004,
'longitude': 30.802497999999996,
'total_confirmed': 483771.0,
'c_last_24hours': 1523.0,
'c_last_7days': 10322.0,
'c_last_30days': 60083.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 24074.0,
'd_last_24hours': 34.0,
'd_last_7days': 217.0,
'd_last_30days': 1470.0},
{'country': 'El Salvador',
'latitude': 13.7942,
'longitude': -88.8965,
'total_confirmed': 156364.0,
'c_last_24hours': 8578.0,
'c_last_7days': 8578.0,
'c_last_30days': 22209.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 4071.0,
'd_last_24hours': 3.0,
'd_last_7days': 23.0,
'd_last_30days': 180.0},
{'country': 'Equatorial Guinea',
'latitude': 1.6508,
'longitude': 10.2679,
'total_confirmed': 15884.0,
'c_last_24hours': 7.0,
'c_last_7days': 10.0,
'c_last_30days': 82.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 182.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Eritrea',
'latitude': 15.1794,
'longitude': 39.7823,
'total_confirmed': 9701.0,
'c_last_24hours': 1.0,
'c_last_7days': 12.0,
'c_last_30days': 176.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 103.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 5.0},
{'country': 'Estonia',
'latitude': 58.5953,
'longitude': 25.0136,
'total_confirmed': 494430.0,
'c_last_24hours': 2281.0,
'c_last_7days': 26441.0,
'c_last_30days': 161850.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 2236.0,
'd_last_24hours': 11.0,
'd_last_7days': 55.0,
'd_last_30days': 200.0},
{'country': 'Eswatini',
'latitude': -26.5225,
'longitude': 31.4659,
'total_confirmed': 69160.0,
'c_last_24hours': 30.0,
'c_last_7days': 139.0,
'c_last_30days': 818.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1390.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 15.0},
{'country': 'Ethiopia',
'latitude': 9.145,
'longitude': 40.4897,
'total_confirmed': 468727.0,
'c_last_24hours': 53.0,
'c_last_7days': 301.0,
'c_last_30days': 3797.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 7462.0,
'd_last_24hours': 2.0,
'd_last_7days': 21.0,
'd_last_30days': 131.0},
{'country': 'Fiji',
'latitude': -17.7134,
'longitude': 178.065,
'total_confirmed': 63930.0,
'c_last_24hours': 174.0,
'c_last_7days': 280.0,
'c_last_30days': 1557.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 834.0,
'd_last_24hours': 1.0,
'd_last_7days': 8.0,
'd_last_30days': 36.0},
{'country': 'Finland',
'latitude': 61.92411,
'longitude': 25.748151,
'total_confirmed': 637757.0,
'c_last_24hours': 28.0,
'c_last_7days': 12294.0,
'c_last_30days': 162326.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 2366.0,
'd_last_24hours': 2.0,
'd_last_7days': 37.0,
'd_last_30days': 424.0},
{'country': 'France',
'latitude': 46.2276,
'longitude': 2.2137,
'total_confirmed': 22877926.0,
'c_last_24hours': 15769.0,
'c_last_7days': 314332.0,
'c_last_30days': 3699887.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 139382.0,
'd_last_24hours': 233.0,
'd_last_7days': 1105.0,
'd_last_30days': 7806.0},
{'country': 'Gabon',
'latitude': -0.8037,
'longitude': 11.6094,
'total_confirmed': 47543.0,
'c_last_24hours': 9.0,
'c_last_7days': 37.0,
'c_last_30days': 821.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 303.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 2.0},
{'country': 'Gambia',
'latitude': 13.4432,
'longitude': -15.3101,
'total_confirmed': 11939.0,
'c_last_24hours': 15.0,
'c_last_7days': 15.0,
'c_last_30days': 97.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 365.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 18.0},
{'country': 'Georgia',
'latitude': 42.3154,
'longitude': 43.3569,
'total_confirmed': 1605472.0,
'c_last_24hours': 2465.0,
'c_last_7days': 39703.0,
'c_last_30days': 442699.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 16163.0,
'd_last_24hours': 25.0,
'd_last_7days': 230.0,
'd_last_30days': 1218.0},
{'country': 'Germany',
'latitude': 51.165690999999995,
'longitude': 10.451526,
'total_confirmed': 14912626.0,
'c_last_24hours': 132801.0,
'c_last_7days': 885951.0,
'c_last_30days': 5066594.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 122949.0,
'd_last_24hours': 236.0,
'd_last_7days': 1041.0,
'd_last_30days': 5159.0},
{'country': 'Ghana',
'latitude': 7.9465,
'longitude': -1.0232,
'total_confirmed': 159674.0,
'c_last_24hours': 550.0,
'c_last_7days': 780.0,
'c_last_30days': 2869.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1442.0,
'd_last_24hours': 0.0,
'd_last_7days': 1.0,
'd_last_30days': 49.0},
{'country': 'Greece',
'latitude': 39.0742,
'longitude': 21.8243,
'total_confirmed': 2421664.0,
'c_last_24hours': 16263.0,
'c_last_7days': 85049.0,
'c_last_30days': 500672.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 25860.0,
'd_last_24hours': 75.0,
'd_last_7days': 375.0,
'd_last_30days': 2488.0},
{'country': 'Grenada',
'latitude': 12.1165,
'longitude': -61.678999999999995,
'total_confirmed': 13690.0,
'c_last_24hours': 11.0,
'c_last_7days': 125.0,
'c_last_30days': 1379.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 216.0,
'd_last_24hours': 0.0,
'd_last_7days': 2.0,
'd_last_30days': 6.0},
{'country': 'Guatemala',
'latitude': 15.7835,
'longitude': -90.2308,
'total_confirmed': 776991.0,
'c_last_24hours': 0.0,
'c_last_7days': 14895.0,
'c_last_30days': 87382.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 16971.0,
'd_last_24hours': 0.0,
'd_last_7days': 138.0,
'd_last_30days': 592.0},
{'country': 'Guinea',
'latitude': 9.9456,
'longitude': -9.6966,
'total_confirmed': 36397.0,
'c_last_24hours': 4.0,
'c_last_7days': 4.0,
'c_last_30days': 384.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 440.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 24.0},
{'country': 'Guinea-Bissau',
'latitude': 11.8037,
'longitude': -15.1804,
'total_confirmed': 8001.0,
'c_last_24hours': 0.0,
'c_last_7days': 48.0,
'c_last_30days': 415.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 167.0,
'd_last_24hours': 0.0,
'd_last_7days': 1.0,
'd_last_30days': 11.0},
{'country': 'Guyana',
'latitude': 4.860416000000001,
'longitude': -58.93018000000001,
'total_confirmed': 62921.0,
'c_last_24hours': 30.0,
'c_last_7days': 175.0,
'c_last_30days': 3118.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1220.0,
'd_last_24hours': 0.0,
'd_last_7days': 5.0,
'd_last_30days': 54.0},
{'country': 'Haiti',
'latitude': 18.9712,
'longitude': -72.2852,
'total_confirmed': 30342.0,
'c_last_24hours': 0.0,
'c_last_7days': 55.0,
'c_last_30days': 1467.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 820.0,
'd_last_24hours': 0.0,
'd_last_7days': 1.0,
'd_last_30days': 36.0},
{'country': 'Holy See',
'latitude': 41.9029,
'longitude': 12.4534,
'total_confirmed': 29.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 0.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 0.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Honduras',
'latitude': 15.2,
'longitude': -86.2419,
'total_confirmed': 411212.0,
'c_last_24hours': 0.0,
'c_last_7days': 2756.0,
'c_last_30days': 19338.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 10720.0,
'd_last_24hours': 0.0,
'd_last_7days': 49.0,
'd_last_30days': 208.0},
{'country': 'Hungary',
'latitude': 47.1625,
'longitude': 19.5033,
'total_confirmed': 1787544.0,
'c_last_24hours': 8370.0,
'c_last_7days': 24666.0,
'c_last_30days': 279186.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 43949.0,
'd_last_24hours': 197.0,
'd_last_7days': 519.0,
'd_last_30days': 2720.0},
{'country': 'Iceland',
'latitude': 64.9631,
'longitude': -19.0208,
'total_confirmed': 129844.0,
'c_last_24hours': 6471.0,
'c_last_7days': 17750.0,
'c_last_30days': 65358.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 62.0,
'd_last_24hours': 1.0,
'd_last_7days': 1.0,
'd_last_30days': 16.0},
{'country': 'India',
'latitude': 20.593684,
'longitude': 78.96288,
'total_confirmed': 42931045.0,
'c_last_24hours': 6915.0,
'c_last_7days': 64014.0,
'c_last_30days': 1628605.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 514023.0,
'd_last_24hours': 180.0,
'd_last_7days': 1401.0,
'd_last_30days': 18973.0},
{'country': 'Indonesia',
'latitude': -0.7893,
'longitude': 113.9213,
'total_confirmed': 5564448.0,
'c_last_24hours': 25054.0,
'c_last_7days': 275034.0,
'c_last_30days': 1221263.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 148335.0,
'd_last_24hours': 262.0,
'd_last_7days': 1537.0,
'd_last_30days': 4032.0},
{'country': 'Iran',
'latitude': 32.427908,
'longitude': 53.68804599999999,
'total_confirmed': 7051429.0,
'c_last_24hours': 10962.0,
'c_last_7days': 67794.0,
'c_last_30days': 707250.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 136838.0,
'd_last_24hours': 207.0,
'd_last_7days': 1339.0,
'd_last_30days': 4414.0},
{'country': 'Iraq',
'latitude': 33.223191,
'longitude': 43.679291,
'total_confirmed': 2302793.0,
'c_last_24hours': 1203.0,
'c_last_7days': 7864.0,
'c_last_30days': 99428.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 24989.0,
'd_last_24hours': 14.0,
'd_last_7days': 92.0,
'd_last_30days': 613.0},
{'country': 'Ireland',
'latitude': 53.1424,
'longitude': -7.6921,
'total_confirmed': 1300422.0,
'c_last_24hours': 8210.0,
'c_last_7days': 20364.0,
'c_last_30days': 130777.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 6497.0,
'd_last_24hours': 22.0,
'd_last_7days': 45.0,
'd_last_30days': 361.0},
{'country': 'Israel',
'latitude': 31.046051000000002,
'longitude': 34.851612,
'total_confirmed': 3634503.0,
'c_last_24hours': 8765.0,
'c_last_7days': 57587.0,
'c_last_30days': 804349.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 10198.0,
'd_last_24hours': 44.0,
'd_last_7days': 160.0,
'd_last_30days': 1473.0},
{'country': 'Italy',
'latitude': 41.87194,
'longitude': 12.56738,
'total_confirmed': 12782836.0,
'c_last_24hours': 18278.0,
'c_last_7days': 228240.0,
'c_last_30days': 1857351.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 154767.0,
'd_last_24hours': 207.0,
'd_last_7days': 1255.0,
'd_last_30days': 8618.0},
{'country': 'Jamaica',
'latitude': 18.1096,
'longitude': -77.2975,
'total_confirmed': 127986.0,
'c_last_24hours': 25.0,
'c_last_7days': 280.0,
'c_last_30days': 3884.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 2813.0,
'd_last_24hours': 0.0,
'd_last_7days': 18.0,
'd_last_30days': 163.0},
{'country': 'Japan',
'latitude': 36.204824,
'longitude': 138.252924,
'total_confirmed': 5005881.0,
'c_last_24hours': 51016.0,
'c_last_7days': 393777.0,
'c_last_30days': 2328279.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 23667.0,
'd_last_24hours': 198.0,
'd_last_7days': 1328.0,
'd_last_30days': 4903.0},
{'country': 'Jordan',
'latitude': 31.24,
'longitude': 36.51,
'total_confirmed': 1631708.0,
'c_last_24hours': 8234.0,
'c_last_7days': 40430.0,
'c_last_30days': 422625.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 13835.0,
'd_last_24hours': 21.0,
'd_last_7days': 104.0,
'd_last_30days': 642.0},
{'country': 'Kazakhstan',
'latitude': 48.0196,
'longitude': 66.9237,
'total_confirmed': 1390670.0,
'c_last_24hours': 229.0,
'c_last_7days': 2569.0,
'c_last_30days': 70856.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 18937.0,
'd_last_24hours': 31.0,
'd_last_7days': 65.0,
'd_last_30days': 483.0},
{'country': 'Kenya',
'latitude': -0.0236,
'longitude': 37.9062,
'total_confirmed': 322946.0,
'c_last_24hours': 16.0,
'c_last_7days': 210.0,
'c_last_30days': 1611.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 5639.0,
'd_last_24hours': 0.0,
'd_last_7days': 1.0,
'd_last_30days': 59.0},
{'country': 'Kiribati',
'latitude': -3.3704,
'longitude': -168.734,
'total_confirmed': 2914.0,
'c_last_24hours': 0.0,
'c_last_7days': 53.0,
'c_last_30days': 2550.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 11.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 11.0},
{'country': 'Korea, South',
'latitude': 35.907757000000004,
'longitude': 127.766922,
'total_confirmed': 3273449.0,
'c_last_24hours': 138993.0,
'c_last_7days': 944267.0,
'c_last_30days': 2427740.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 8170.0,
'd_last_24hours': 112.0,
'd_last_7days': 563.0,
'd_last_30days': 1415.0},
{'country': 'Kosovo',
'latitude': 42.602636,
'longitude': 20.902977,
'total_confirmed': 226248.0,
'c_last_24hours': 55.0,
'c_last_7days': 841.0,
'c_last_30days': 21903.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 3114.0,
'd_last_24hours': 1.0,
'd_last_7days': 12.0,
'd_last_30days': 102.0},
{'country': 'Kuwait',
'latitude': 29.311659999999996,
'longitude': 47.481766,
'total_confirmed': 619894.0,
'c_last_24hours': 584.0,
'c_last_7days': 4497.0,
'c_last_30days': 80240.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 2538.0,
'd_last_24hours': 1.0,
'd_last_7days': 7.0,
'd_last_30days': 42.0},
{'country': 'Kyrgyzstan',
'latitude': 41.20438,
'longitude': 74.766098,
'total_confirmed': 200527.0,
'c_last_24hours': 27.0,
'c_last_7days': 159.0,
'c_last_30days': 2416.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 2958.0,
'd_last_24hours': 1.0,
'd_last_7days': 11.0,
'd_last_30days': 82.0},
{'country': 'Laos',
'latitude': 19.856270000000002,
'longitude': 102.495496,
'total_confirmed': 142743.0,
'c_last_24hours': 134.0,
'c_last_7days': 1302.0,
'c_last_30days': 9219.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 621.0,
'd_last_24hours': 0.0,
'd_last_7days': 8.0,
'd_last_30days': 77.0},
{'country': 'Latvia',
'latitude': 56.8796,
'longitude': 24.6032,
'total_confirmed': 653120.0,
'c_last_24hours': 3616.0,
'c_last_7days': 51520.0,
'c_last_30days': 264131.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 5239.0,
'd_last_24hours': 9.0,
'd_last_7days': 109.0,
'd_last_30days': 371.0},
{'country': 'Lebanon',
'latitude': 33.8547,
'longitude': 35.8623,
'total_confirmed': 1068710.0,
'c_last_24hours': 1870.0,
'c_last_7days': 15458.0,
'c_last_30days': 153781.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 10091.0,
'd_last_24hours': 12.0,
'd_last_7days': 84.0,
'd_last_30days': 501.0},
{'country': 'Lesotho',
'latitude': -29.61,
'longitude': 28.2336,
'total_confirmed': 32612.0,
'c_last_24hours': 0.0,
'c_last_7days': 13.0,
'c_last_30days': 436.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 696.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 3.0},
{'country': 'Liberia',
'latitude': 6.4280550000000005,
'longitude': -9.429499,
'total_confirmed': 7384.0,
'c_last_24hours': 24.0,
'c_last_7days': 24.0,
'c_last_30days': 130.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 294.0,
'd_last_24hours': 4.0,
'd_last_7days': 4.0,
'd_last_30days': 4.0},
{'country': 'Libya',
'latitude': 26.3351,
'longitude': 17.228331,
'total_confirmed': 494446.0,
'c_last_24hours': 898.0,
'c_last_7days': 5879.0,
'c_last_30days': 69209.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 6261.0,
'd_last_24hours': 11.0,
'd_last_7days': 51.0,
'd_last_30days': 254.0},
{'country': 'Liechtenstein',
'latitude': 47.14,
'longitude': 9.55,
'total_confirmed': 12001.0,
'c_last_24hours': 192.0,
'c_last_7days': 639.0,
'c_last_30days': 3147.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 78.0,
'd_last_24hours': 0.0,
'd_last_7days': 2.0,
'd_last_30days': 5.0},
{'country': 'Lithuania',
'latitude': 55.1694,
'longitude': 23.8813,
'total_confirmed': 902554.0,
'c_last_24hours': 2035.0,
'c_last_7days': 31096.0,
'c_last_30days': 229036.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 8421.0,
'd_last_24hours': 11.0,
'd_last_7days': 128.0,
'd_last_30days': 561.0},
{'country': 'Luxembourg',
'latitude': 49.8153,
'longitude': 6.1296,
'total_confirmed': 183177.0,
'c_last_24hours': 1274.0,
'c_last_7days': 3872.0,
'c_last_30days': 29742.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 991.0,
'd_last_24hours': 3.0,
'd_last_7days': 5.0,
'd_last_30days': 43.0},
{'country': 'MS Zaandam',
'latitude': 0.0,
'longitude': 0.0,
'total_confirmed': 9.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 0.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 2.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Madagascar',
'latitude': -18.766947000000002,
'longitude': 46.869107,
'total_confirmed': 63659.0,
'c_last_24hours': 0.0,
'c_last_7days': 226.0,
'c_last_30days': 4340.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1366.0,
'd_last_24hours': 0.0,
'd_last_7days': 16.0,
'd_last_30days': 92.0},
{'country': 'Malawi',
'latitude': -13.2543,
'longitude': 34.3015,
'total_confirmed': 85339.0,
'c_last_24hours': 11.0,
'c_last_7days': 101.0,
'c_last_30days': 919.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 2615.0,
'd_last_24hours': 0.0,
'd_last_7days': 6.0,
'd_last_30days': 57.0},
{'country': 'Malaysia',
'latitude': 4.210483999999999,
'longitude': 101.97576600000001,
'total_confirmed': 3442736.0,
'c_last_24hours': 23100.0,
'c_last_7days': 168778.0,
'c_last_30days': 576752.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 32749.0,
'd_last_24hours': 75.0,
'd_last_7days': 316.0,
'd_last_30days': 784.0},
{'country': 'Maldives',
'latitude': 3.2028,
'longitude': 73.2207,
'total_confirmed': 170192.0,
'c_last_24hours': 560.0,
'c_last_7days': 3667.0,
'c_last_30days': 34066.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 297.0,
'd_last_24hours': 0.0,
'd_last_7days': 5.0,
'd_last_30days': 22.0},
{'country': 'Mali',
'latitude': 17.570692,
'longitude': -3.9961660000000006,
'total_confirmed': 30381.0,
'c_last_24hours': 3.0,
'c_last_7days': 29.0,
'c_last_30days': 336.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 722.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 11.0},
{'country': 'Malta',
'latitude': 35.9375,
'longitude': 14.3754,
'total_confirmed': 71275.0,
'c_last_24hours': 40.0,
'c_last_7days': 446.0,
'c_last_30days': 3727.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 605.0,
'd_last_24hours': 1.0,
'd_last_7days': 6.0,
'd_last_30days': 58.0},
{'country': 'Marshall Islands',
'latitude': 7.1315,
'longitude': 171.1845,
'total_confirmed': 7.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 0.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 0.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Mauritania',
'latitude': 21.0079,
'longitude': -10.9408,
'total_confirmed': 58633.0,
'c_last_24hours': 1.0,
'c_last_7days': 15.0,
'c_last_30days': 435.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 979.0,
'd_last_24hours': 0.0,
'd_last_7days': 2.0,
'd_last_30days': 26.0},
{'country': 'Mauritius',
'latitude': -20.348404000000002,
'longitude': 57.552152,
'total_confirmed': 70862.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 128.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 786.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Mexico',
'latitude': 23.6345,
'longitude': -102.5528,
'total_confirmed': 5508629.0,
'c_last_24hours': 2524.0,
'c_last_7days': 90372.0,
'c_last_30days': 578560.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 318149.0,
'd_last_24hours': 63.0,
'd_last_7days': 2363.0,
'd_last_30days': 12256.0},
{'country': 'Micronesia',
'latitude': 7.4256,
'longitude': 150.5508,
'total_confirmed': 1.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 0.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 0.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Moldova',
'latitude': 47.4116,
'longitude': 28.3699,
'total_confirmed': 501800.0,
'c_last_24hours': 488.0,
'c_last_7days': 4824.0,
'c_last_30days': 63551.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 11216.0,
'd_last_24hours': 3.0,
'd_last_7days': 80.0,
'd_last_30days': 581.0},
{'country': 'Monaco',
'latitude': 43.7333,
'longitude': 7.4167,
'total_confirmed': 9359.0,
'c_last_24hours': 0.0,
'c_last_7days': 92.0,
'c_last_30days': 1176.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 51.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 5.0},
{'country': 'Mongolia',
'latitude': 46.8625,
'longitude': 103.8467,
'total_confirmed': 908157.0,
'c_last_24hours': 2189.0,
'c_last_7days': 8451.0,
'c_last_30days': 71033.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 2170.0,
'd_last_24hours': 2.0,
'd_last_7days': 9.0,
'd_last_30days': 61.0},
{'country': 'Montenegro',
'latitude': 42.708678000000006,
'longitude': 19.37439,
'total_confirmed': 230207.0,
'c_last_24hours': 87.0,
'c_last_7days': 985.0,
'c_last_30days': 10904.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 2680.0,
'd_last_24hours': 4.0,
'd_last_7days': 11.0,
'd_last_30days': 125.0},
{'country': 'Morocco',
'latitude': 31.7917,
'longitude': -7.0926,
'total_confirmed': 1160975.0,
'c_last_24hours': 83.0,
'c_last_7days': 1391.0,
'c_last_30days': 29580.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 15988.0,
'd_last_24hours': 10.0,
'd_last_7days': 66.0,
'd_last_30days': 626.0},
{'country': 'Mozambique',
'latitude': -18.665695,
'longitude': 35.529562,
'total_confirmed': 225047.0,
'c_last_24hours': 9.0,
'c_last_7days': 110.0,
'c_last_30days': 1236.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 2192.0,
'd_last_24hours': 0.0,
'd_last_7days': 2.0,
'd_last_30days': 22.0},
{'country': 'Namibia',
'latitude': -22.9576,
'longitude': 18.4904,
'total_confirmed': 157225.0,
'c_last_24hours': 15.0,
'c_last_7days': 191.0,
'c_last_30days': 1385.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 4007.0,
'd_last_24hours': 0.0,
'd_last_7days': 5.0,
'd_last_30days': 40.0},
{'country': 'Nepal',
'latitude': 28.1667,
'longitude': 84.25,
'total_confirmed': 976984.0,
'c_last_24hours': 119.0,
'c_last_7days': 879.0,
'c_last_30days': 26543.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 11936.0,
'd_last_24hours': 1.0,
'd_last_7days': 8.0,
'd_last_30days': 201.0},
{'country': 'Netherlands',
'latitude': 52.1326,
'longitude': 5.2913,
'total_confirmed': 6535520.0,
'c_last_24hours': 92108.0,
'c_last_7days': 260120.0,
'c_last_30days': 2016753.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 22163.0,
'd_last_24hours': 54.0,
'd_last_7days': 68.0,
'd_last_30days': 363.0},
{'country': 'New Zealand',
'latitude': -40.9006,
'longitude': 174.886,
'total_confirmed': 118814.0,
'c_last_24hours': 32674.0,
'c_last_7days': 79467.0,
'c_last_30days': 102396.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 56.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 3.0},
{'country': 'Nicaragua',
'latitude': 12.865416,
'longitude': -85.207229,
'total_confirmed': 18004.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 354.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 224.0,
'd_last_24hours': 0.0,
'd_last_7days': 1.0,
'd_last_30days': 4.0},
{'country': 'Niger',
'latitude': 17.607789,
'longitude': 8.081666,
'total_confirmed': 8754.0,
'c_last_24hours': 3.0,
'c_last_7days': 11.0,
'c_last_30days': 105.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 307.0,
'd_last_24hours': 0.0,
'd_last_7days': 1.0,
'd_last_30days': 9.0},
{'country': 'Nigeria',
'latitude': 9.082,
'longitude': 8.6753,
'total_confirmed': 254560.0,
'c_last_24hours': 35.0,
'c_last_7days': 208.0,
'c_last_30days': 1537.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 3142.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 7.0},
{'country': 'North Macedonia',
'latitude': 41.6086,
'longitude': 21.7453,
'total_confirmed': 297262.0,
'c_last_24hours': 181.0,
'c_last_7days': 2855.0,
'c_last_30days': 29071.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 9010.0,
'd_last_24hours': 9.0,
'd_last_7days': 73.0,
'd_last_30days': 627.0},
{'country': 'Norway',
'latitude': 60.472,
'longitude': 8.4689,
'total_confirmed': 1242160.0,
'c_last_24hours': 10394.0,
'c_last_7days': 73123.0,
'c_last_30days': 477099.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1598.0,
'd_last_24hours': 0.0,
'd_last_7days': 49.0,
'd_last_30days': 159.0},
{'country': 'Oman',
'latitude': 21.512583,
'longitude': 55.923255000000005,
'total_confirmed': 382244.0,
'c_last_24hours': 863.0,
'c_last_7days': 4296.0,
'c_last_30days': 45784.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 4244.0,
'd_last_24hours': 2.0,
'd_last_7days': 6.0,
'd_last_30days': 110.0},
{'country': 'Pakistan',
'latitude': 30.3753,
'longitude': 69.3451,
'total_confirmed': 1510221.0,
'c_last_24hours': 861.0,
'c_last_7days': 6348.0,
'c_last_30days': 85182.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 30196.0,
'd_last_24hours': 18.0,
'd_last_7days': 100.0,
'd_last_30days': 927.0},
{'country': 'Palau',
'latitude': 7.515,
'longitude': 134.5825,
'total_confirmed': 3781.0,
'c_last_24hours': 55.0,
'c_last_7days': 154.0,
'c_last_30days': 2303.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 6.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 6.0},
{'country': 'Panama',
'latitude': 8.538,
'longitude': -80.7821,
'total_confirmed': 755498.0,
'c_last_24hours': 236.0,
'c_last_7days': 3325.0,
'c_last_30days': 58374.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 8083.0,
'd_last_24hours': 4.0,
'd_last_7days': 47.0,
'd_last_30days': 367.0},
{'country': 'Papua New Guinea',
'latitude': -6.314993,
'longitude': 143.95555,
'total_confirmed': 41290.0,
'c_last_24hours': 252.0,
'c_last_7days': 994.0,
'c_last_30days': 4424.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 638.0,
'd_last_24hours': 0.0,
'd_last_7days': 2.0,
'd_last_30days': 41.0},
{'country': 'Paraguay',
'latitude': -23.4425,
'longitude': -58.4438,
'total_confirmed': 641321.0,
'c_last_24hours': 0.0,
'c_last_7days': 4096.0,
'c_last_30days': 70852.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 18359.0,
'd_last_24hours': 0.0,
'd_last_7days': 100.0,
'd_last_30days': 1128.0},
{'country': 'Peru',
'latitude': -9.19,
'longitude': -75.0152,
'total_confirmed': 3517260.0,
'c_last_24hours': 996.0,
'c_last_7days': 16380.0,
'c_last_30days': 292854.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 210672.0,
'd_last_24hours': 134.0,
'd_last_7days': 864.0,
'd_last_30days': 5167.0},
{'country': 'Philippines',
'latitude': 12.879721,
'longitude': 121.77401699999999,
'total_confirmed': 3661997.0,
'c_last_24hours': 948.0,
'c_last_7days': 7713.0,
'c_last_30days': 116317.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 56451.0,
'd_last_24hours': 50.0,
'd_last_7days': 675.0,
'd_last_30days': 2560.0},
{'country': 'Poland',
'latitude': 51.9194,
'longitude': 19.1451,
'total_confirmed': 5667054.0,
'c_last_24hours': 6561.0,
'c_last_7days': 84837.0,
'c_last_30days': 814377.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 111317.0,
'd_last_24hours': 1.0,
'd_last_7days': 1160.0,
'd_last_30days': 6156.0},
{'country': 'Portugal',
'latitude': 39.3999,
'longitude': -8.2245,
'total_confirmed': 3262618.0,
'c_last_24hours': 4209.0,
'c_last_7days': 56337.0,
'c_last_30days': 650732.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 21063.0,
'd_last_24hours': 24.0,
'd_last_7days': 169.0,
'd_last_30days': 1207.0},
{'country': 'Qatar',
'latitude': 25.3548,
'longitude': 51.1839,
'total_confirmed': 356982.0,
'c_last_24hours': 311.0,
'c_last_7days': 1950.0,
'c_last_30days': 19344.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 670.0,
'd_last_24hours': 2.0,
'd_last_7days': 8.0,
'd_last_30days': 25.0},
{'country': 'Romania',
'latitude': 45.9432,
'longitude': 24.9668,
'total_confirmed': 2733484.0,
'c_last_24hours': 4757.0,
'c_last_7days': 47395.0,
'c_last_30days': 537090.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 63414.0,
'd_last_24hours': 61.0,
'd_last_7days': 575.0,
'd_last_30days': 3430.0},
{'country': 'Russia',
'latitude': 61.52401,
'longitude': 105.31875600000001,
'total_confirmed': 16161596.0,
'c_last_24hours': 105745.0,
'c_last_7days': 731056.0,
'c_last_30days': 4614263.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 344655.0,
'd_last_24hours': 721.0,
'd_last_7days': 4554.0,
'd_last_30days': 20595.0},
{'country': 'Rwanda',
'latitude': -1.9403,
'longitude': 29.8739,
'total_confirmed': 129502.0,
'c_last_24hours': 13.0,
'c_last_7days': 83.0,
'c_last_30days': 661.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1457.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 19.0},
{'country': 'Saint Kitts and Nevis',
'latitude': 17.357822,
'longitude': -62.782998,
'total_confirmed': 5530.0,
'c_last_24hours': 0.0,
'c_last_7days': 7.0,
'c_last_30days': 108.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 42.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 9.0},
{'country': 'Saint Lucia',
'latitude': 13.9094,
'longitude': -60.9789,
'total_confirmed': 22694.0,
'c_last_24hours': 27.0,
'c_last_7days': 126.0,
'c_last_30days': 1785.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 358.0,
'd_last_24hours': 0.0,
'd_last_7days': 1.0,
'd_last_30days': 32.0},
{'country': 'Saint Vincent and the Grenadines',
'latitude': 12.9843,
'longitude': -61.2872,
'total_confirmed': 8309.0,
'c_last_24hours': 4.0,
'c_last_7days': 12.0,
'c_last_30days': -1138.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 106.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 13.0},
{'country': 'Samoa',
'latitude': -13.759,
'longitude': -172.1046,
'total_confirmed': 33.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 3.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 0.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'San Marino',
'latitude': 43.9424,
'longitude': 12.4578,
'total_confirmed': 14369.0,
'c_last_24hours': 59.0,
'c_last_7days': 162.0,
'c_last_30days': 1907.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 112.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 3.0},
{'country': 'Sao Tome and Principe',
'latitude': 0.1864,
'longitude': 6.6131,
'total_confirmed': 5934.0,
'c_last_24hours': 0.0,
'c_last_7days': 4.0,
'c_last_30days': 49.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 72.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 3.0},
{'country': 'Saudi Arabia',
'latitude': 23.885942,
'longitude': 45.079162,
'total_confirmed': 745027.0,
'c_last_24hours': 653.0,
'c_last_7days': 3790.0,
'c_last_30days': 61974.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 8998.0,
'd_last_24hours': 2.0,
'd_last_7days': 11.0,
'd_last_30days': 62.0},
{'country': 'Senegal',
'latitude': 14.4974,
'longitude': -14.4524,
'total_confirmed': 85693.0,
'c_last_24hours': 17.0,
'c_last_7days': 93.0,
'c_last_30days': 757.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1960.0,
'd_last_24hours': 0.0,
'd_last_7days': 1.0,
'd_last_30days': 16.0},
{'country': 'Serbia',
'latitude': 44.0165,
'longitude': 21.0059,
'total_confirmed': 1910975.0,
'c_last_24hours': 2703.0,
'c_last_7days': 18788.0,
'c_last_30days': 248529.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 15241.0,
'd_last_24hours': 42.0,
'd_last_7days': 283.0,
'd_last_30days': 1671.0},
{'country': 'Seychelles',
'latitude': -4.6796,
'longitude': 55.492,
'total_confirmed': 39336.0,
'c_last_24hours': 85.0,
'c_last_7days': 155.0,
'c_last_30days': 2777.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 163.0,
'd_last_24hours': 3.0,
'd_last_7days': 3.0,
'd_last_30days': 14.0},
{'country': 'Sierra Leone',
'latitude': 8.460555000000001,
'longitude': -11.779889,
'total_confirmed': 7665.0,
'c_last_24hours': 0.0,
'c_last_7days': 2.0,
'c_last_30days': 43.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 125.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Singapore',
'latitude': 1.2833,
'longitude': 103.8333,
'total_confirmed': 724424.0,
'c_last_24hours': 13544.0,
'c_last_7days': 102131.0,
'c_last_30days': 376094.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1019.0,
'd_last_24hours': 12.0,
'd_last_7days': 63.0,
'd_last_30days': 165.0},
{'country': 'Slovakia',
'latitude': 48.669,
'longitude': 19.699,
'total_confirmed': 2119358.0,
'c_last_24hours': 7086.0,
'c_last_7days': 93712.0,
'c_last_30days': 557251.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 18485.0,
'd_last_24hours': 32.0,
'd_last_7days': 206.0,
'd_last_30days': 668.0},
{'country': 'Slovenia',
'latitude': 46.1512,
'longitude': 14.9955,
'total_confirmed': 892620.0,
'c_last_24hours': 670.0,
'c_last_7days': 10256.0,
'c_last_30days': 190419.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 6307.0,
'd_last_24hours': 15.0,
'd_last_7days': 71.0,
'd_last_30days': 450.0},
{'country': 'Solomon Islands',
'latitude': -9.6457,
'longitude': 160.1562,
'total_confirmed': 7049.0,
'c_last_24hours': 112.0,
'c_last_7days': 1081.0,
'c_last_30days': 6151.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 99.0,
'd_last_24hours': 3.0,
'd_last_7days': 14.0,
'd_last_30days': 94.0},
{'country': 'Somalia',
'latitude': 5.152149,
'longitude': 46.199616,
'total_confirmed': 26313.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 925.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1348.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 13.0},
{'country': 'South Africa',
'latitude': -30.5595,
'longitude': 22.9375,
'total_confirmed': 3674042.0,
'c_last_24hours': 785.0,
'c_last_7days': 12010.0,
'c_last_30days': 70186.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 99412.0,
'd_last_24hours': 183.0,
'd_last_7days': 544.0,
'd_last_30days': 4390.0},
{'country': 'South Sudan',
'latitude': 6.877000000000001,
'longitude': 31.307,
'total_confirmed': 16973.0,
'c_last_24hours': 18.0,
'c_last_7days': 33.0,
'c_last_30days': 194.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 137.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Spain',
'latitude': 40.463667,
'longitude': -3.7492199999999998,
'total_confirmed': 10977524.0,
'c_last_24hours': 0.0,
'c_last_7days': 97331.0,
'c_last_30days': 1198394.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 99410.0,
'd_last_24hours': 0.0,
'd_last_7days': 775.0,
'd_last_30days': 6444.0},
{'country': 'Sri Lanka',
'latitude': 7.873054,
'longitude': 80.77179699999999,
'total_confirmed': 646034.0,
'c_last_24hours': 997.0,
'c_last_7days': 6737.0,
'c_last_30days': 35931.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 16222.0,
'd_last_24hours': 32.0,
'd_last_7days': 167.0,
'd_last_30days': 802.0},
{'country': 'Sudan',
'latitude': 12.8628,
'longitude': 30.2176,
'total_confirmed': 61458.0,
'c_last_24hours': 82.0,
'c_last_7days': 207.0,
'c_last_30days': 4352.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 3908.0,
'd_last_24hours': 7.0,
'd_last_7days': 14.0,
'd_last_30days': 486.0},
{'country': 'Summer Olympics 2020',
'latitude': 35.6491,
'longitude': 139.7737,
'total_confirmed': 865.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 0.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 0.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Suriname',
'latitude': 3.9193,
'longitude': -56.0278,
'total_confirmed': 78216.0,
'c_last_24hours': 93.0,
'c_last_7days': 336.0,
'c_last_30days': 5054.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1317.0,
'd_last_24hours': 1.0,
'd_last_7days': 3.0,
'd_last_30days': 57.0},
{'country': 'Sweden',
'latitude': 60.128161,
'longitude': 18.643501,
'total_confirmed': 2441142.0,
'c_last_24hours': 0.0,
'c_last_7days': 9777.0,
'c_last_30days': 370686.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 17142.0,
'd_last_24hours': 0.0,
'd_last_7days': 209.0,
'd_last_30days': 1287.0},
{'country': 'Switzerland',
'latitude': 46.8182,
'longitude': 8.2275,
'total_confirmed': 2801951.0,
'c_last_24hours': 36733.0,
'c_last_7days': 90333.0,
'c_last_30days': 670874.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 13101.0,
'd_last_24hours': 21.0,
'd_last_7days': 57.0,
'd_last_30days': 417.0},
{'country': 'Syria',
'latitude': 34.802075,
'longitude': 38.996815000000005,
'total_confirmed': 54575.0,
'c_last_24hours': 95.0,
'c_last_7days': 663.0,
'c_last_30days': 3234.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 3075.0,
'd_last_24hours': 4.0,
'd_last_7days': 19.0,
'd_last_30days': 89.0},
{'country': 'Taiwan*',
'latitude': 23.7,
'longitude': 121.0,
'total_confirmed': 20489.0,
'c_last_24hours': 56.0,
'c_last_7days': 389.0,
'c_last_30days': 1754.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 853.0,
'd_last_24hours': 0.0,
'd_last_7days': 1.0,
'd_last_30days': 2.0},
{'country': 'Tajikistan',
'latitude': 38.861,
'longitude': 71.2761,
'total_confirmed': 17786.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 123.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 125.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Tanzania',
'latitude': -6.369028,
'longitude': 34.888822,
'total_confirmed': 33620.0,
'c_last_24hours': 0.0,
'c_last_7days': 0.0,
'c_last_30days': 700.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 798.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 20.0},
{'country': 'Thailand',
'latitude': 15.870032,
'longitude': 100.992541,
'total_confirmed': 2891927.0,
'c_last_24hours': 0.0,
'c_last_7days': 121134.0,
'c_last_30days': 451385.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 22933.0,
'd_last_24hours': 0.0,
'd_last_7days': 203.0,
'd_last_30days': 776.0},
{'country': 'Timor-Leste',
'latitude': -8.874217,
'longitude': 125.727539,
'total_confirmed': 22693.0,
'c_last_24hours': 25.0,
'c_last_7days': 208.0,
'c_last_30days': 2785.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 128.0,
'd_last_24hours': 0.0,
'd_last_7days': 3.0,
'd_last_30days': 6.0},
{'country': 'Togo',
'latitude': 8.6195,
'longitude': 0.8248,
'total_confirmed': 36798.0,
'c_last_24hours': 0.0,
'c_last_7days': 43.0,
'c_last_30days': 307.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 272.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 4.0},
{'country': 'Tonga',
'latitude': -21.179000000000002,
'longitude': -175.1982,
'total_confirmed': 355.0,
'c_last_24hours': 0.0,
'c_last_7days': 101.0,
'c_last_30days': 354.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 0.0,
'd_last_24hours': -1.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Trinidad and Tobago',
'latitude': 10.6918,
'longitude': -61.2225,
'total_confirmed': 127767.0,
'c_last_24hours': 363.0,
'c_last_7days': 3279.0,
'c_last_30days': 16577.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 3628.0,
'd_last_24hours': 6.0,
'd_last_7days': 46.0,
'd_last_30days': 233.0},
{'country': 'Tunisia',
'latitude': 33.886917,
'longitude': 9.537499,
'total_confirmed': 998230.0,
'c_last_24hours': 476.0,
'c_last_7days': 9901.0,
'c_last_30days': 97123.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 27784.0,
'd_last_24hours': 11.0,
'd_last_7days': 272.0,
'd_last_30days': 1555.0},
{'country': 'Turkey',
'latitude': 38.9637,
'longitude': 35.2433,
'total_confirmed': 14089456.0,
'c_last_24hours': 64275.0,
'c_last_7days': 413875.0,
'c_last_30days': 2562835.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 94445.0,
'd_last_24hours': 213.0,
'd_last_7days': 1455.0,
'd_last_30days': 7211.0},
{'country': 'US',
'latitude': 40.0,
'longitude': -100.0,
'total_confirmed': 79045043.0,
'c_last_24hours': 105840.0,
'c_last_7days': 396350.0,
'c_last_30days': 4593456.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 950490.0,
'd_last_24hours': 2093.0,
'd_last_7days': 11426.0,
'd_last_30days': 63650.0},
{'country': 'Uganda',
'latitude': 1.373333,
'longitude': 32.290275,
'total_confirmed': 163307.0,
'c_last_24hours': 57.0,
'c_last_7days': 169.0,
'c_last_30days': 1614.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 3588.0,
'd_last_24hours': 0.0,
'd_last_7days': 3.0,
'd_last_30days': 61.0},
{'country': 'Ukraine',
'latitude': 48.3794,
'longitude': 31.1656,
'total_confirmed': 5040518.0,
'c_last_24hours': 0.0,
'c_last_7days': 54357.0,
'c_last_30days': 808375.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 112459.0,
'd_last_24hours': 0.0,
'd_last_7days': 597.0,
'd_last_30days': 5666.0},
{'country': 'United Arab Emirates',
'latitude': 23.424076,
'longitude': 53.847818000000004,
'total_confirmed': 879973.0,
'c_last_24hours': 605.0,
'c_last_7days': 4089.0,
'c_last_30days': 36943.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 2301.0,
'd_last_24hours': 0.0,
'd_last_7days': 4.0,
'd_last_30days': 61.0},
{'country': 'United Kingdom',
'latitude': 55.3781,
'longitude': -3.4360000000000004,
'total_confirmed': 19021076.0,
'c_last_24hours': 82530.0,
'c_last_7days': 194108.0,
'c_last_30days': 2438389.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 161934.0,
'd_last_24hours': 137.0,
'd_last_7days': 550.0,
'd_last_30days': 5712.0},
{'country': 'Uruguay',
'latitude': -32.5228,
'longitude': -55.7658,
'total_confirmed': 842462.0,
'c_last_24hours': 1951.0,
'c_last_7days': 18678.0,
'c_last_30days': 181021.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 6985.0,
'd_last_24hours': 8.0,
'd_last_7days': 80.0,
'd_last_30days': 526.0},
{'country': 'Uzbekistan',
'latitude': 41.377491,
'longitude': 64.585262,
'total_confirmed': 236405.0,
'c_last_24hours': 106.0,
'c_last_7days': 803.0,
'c_last_30days': 14486.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1635.0,
'd_last_24hours': 2.0,
'd_last_7days': 12.0,
'd_last_30days': 74.0},
{'country': 'Vanuatu',
'latitude': -15.3767,
'longitude': 166.9592,
'total_confirmed': 19.0,
'c_last_24hours': 0.0,
'c_last_7days': 4.0,
'c_last_30days': 12.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 1.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Venezuela',
'latitude': 6.4238,
'longitude': -66.5897,
'total_confirmed': 515124.0,
'c_last_24hours': 809.0,
'c_last_7days': 3352.0,
'c_last_30days': 31103.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 5636.0,
'd_last_24hours': 5.0,
'd_last_7days': 20.0,
'd_last_30days': 196.0},
{'country': 'Vietnam',
'latitude': 14.058323999999999,
'longitude': 108.277199,
'total_confirmed': 3443485.0,
'c_last_24hours': 122480.0,
'c_last_7days': 552963.0,
'c_last_30days': 1180432.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 40522.0,
'd_last_24hours': 378.0,
'd_last_7days': 840.0,
'd_last_30days': 2854.0},
{'country': 'West Bank and Gaza',
'latitude': 31.9522,
'longitude': 35.2332,
'total_confirmed': 645947.0,
'c_last_24hours': 5733.0,
'c_last_7days': 9892.0,
'c_last_30days': 140955.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 5501.0,
'd_last_24hours': 43.0,
'd_last_7days': 76.0,
'd_last_30days': 425.0},
{'country': 'Winter Olympics 2022',
'latitude': 39.9042,
'longitude': 116.4074,
'total_confirmed': 521.0,
'c_last_24hours': 3.0,
'c_last_7days': 12.0,
'c_last_30days': 310.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 0.0,
'd_last_24hours': 0.0,
'd_last_7days': 0.0,
'd_last_30days': 0.0},
{'country': 'Yemen',
'latitude': 15.552726999999999,
'longitude': 48.516388,
'total_confirmed': 11771.0,
'c_last_24hours': 2.0,
'c_last_7days': 25.0,
'c_last_30days': 829.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 2135.0,
'd_last_24hours': 1.0,
'd_last_7days': 11.0,
'd_last_30days': 124.0},
{'country': 'Zambia',
'latitude': -13.133897,
'longitude': 27.849332,
'total_confirmed': 312750.0,
'c_last_24hours': 43.0,
'c_last_7days': 1158.0,
'c_last_30days': 7828.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 3952.0,
'd_last_24hours': 0.0,
'd_last_7days': 6.0,
'd_last_30days': 37.0},
{'country': 'Zimbabwe',
'latitude': -19.015438,
'longitude': 29.154857,
'total_confirmed': 236380.0,
'c_last_24hours': 577.0,
'c_last_7days': 2400.0,
'c_last_30days': 6920.0,
'total_recovered': 0.0,
'r_last_24hours': 0.0,
'r_last_7days': 0.0,
'r_last_30days': 0.0,
'total_deaths': 5395.0,
'd_last_24hours': 2.0,
'd_last_7days': 7.0,
'd_last_30days': 58.0}]

export default covid_data_raw;