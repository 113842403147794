import React from "react";



const NotFound = props => (
    
    <div class="alert alert-dark" role="alert">
    
        Sorry, the page your are looking for does not exists!

    </div>
    
);
    
export default NotFound;