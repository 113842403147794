import React from "react";


import Header from "../Header";
// import Search from "./Search";
import ListData from "./ListData";
import SingleCountry from "./SingleCountry";

import covid_data_raw from "../../data/covid_data";


class COVID19_Index extends React.Component{

    state = {
        covid_data: [],
        single_country_data: [],
        sort_by: {}
    };


    
    // Load the covid data to the state
    componentDidMount() {

        const covid_data = [...covid_data_raw]
        // let sort_by = { };

        covid_data.sort((a, b) => (b.total_confirmed - a.total_confirmed))

        this.setState({ covid_data })
        this.setState({ single_country_data : covid_data[0] })
        this.setState({sort_by:'total_confirmed'})


    }



    single_country = (single_data, key) => {

        console.log("single_country key:",  key);
        console.log("single_data :",  single_data);
        
        let single_country_data = { };

        single_country_data = single_data;

        this.setState({ single_country_data })
    }


    toggle_sort = (sort_key) => {
        console.log("Sort entered: ", sort_key );
        
        const covid_data = [...covid_data_raw];
        // let sort_by = {};

        if (sort_key === 'country'){
            covid_data.sort((a, b) => (b.country - a.country));
            this.setState({ covid_data });
            this.setState({sort_by:'country'})


        

        } 
        else if(sort_key === 'total_confirmed'){
                covid_data.sort((a, b) => (b.total_confirmed - a.total_confirmed));
                this.setState({ covid_data });
                this.setState({sort_by:'total_confirmed'})


        }

        else if(sort_key === 'total_recovered'){
            covid_data.sort((a, b) => (b.total_recovered - a.total_recovered));
            this.setState({ covid_data });
            this.setState({sort_by:'total_recovered'})


        }
        else if (sort_key === 'total_deaths'){
            covid_data.sort((a, b) => (b.total_deaths - a.total_deaths));
            this.setState({ covid_data });
            this.setState({sort_by:'total_deaths'})


        };


    }




    render(){

        let rank = 1;
        const isSort = this.state.sort_by;


        return (

            <React.Fragment>
            
                <Header/>
                <div className="container">

                    
                    <div className="row">
                        <div className="col-md-7 second-div">

                            {/* <nav aria-label="breadcrumb">
                                <ol className="breadcrumb case-text">

                                <span className="label label-info" >
                                TIME
                                </span> &nbsp;&nbsp;&nbsp;
                                <li className="breadcrumb-item" onClick = { () => this.toggle_sort('country')}> 
                                    <span className={isSort==="country" ? "label label-primary" : "" }  >All Time </span>
                                </li>
                                <li className="breadcrumb-item" onClick = { () => this.toggle_sort('country')}>
                                    <span className={isSort==="country" ? "label label-primary" : "" }  >24 Hours </span>
                                </li>
                                <li className="breadcrumb-item" onClick = { () => this.toggle_sort('total_confirmed')}>
                                    <span className={isSort==="country" ? "label label-primary" : "" }  >1 Week </span>
                                </li>
                                <li className="breadcrumb-item" onClick= { () => this.toggle_sort('total_recovered')}>
                                    <span className={isSort==="country" ? "label label-primary" : "" }  > 1 Month </span>
                                </li>


                                </ol>
                            </nav>  */}

                            <nav aria-label="breadcrumb"><span className="label label-info" ><b>Last Updated:</b> March 1st, 2022</span></nav>
                            <table className="table table-hover table-responsive  table-wrapper-scroll-y my-custom-scrollbar ">
                                <thead>
                                    <tr>
                                    <th scope="col"><span className={isSort==="country" ? "label label-primary" : "" }  >#</span></th>
                                    <th scope="col" style={{width: 25+"%"}} onClick= { () => this.toggle_sort('country')}><span className={isSort==="country" ? "label label-primary" : "" }  >Country</span></th>
                                    <th scope="col" onClick = { () => this.toggle_sort('total_confirmed')}><span className={isSort==="total_confirmed" ? "label label-primary" : "" }  >Confirmed </span></th>
                                    <th scope="col" onClick = { () => this.toggle_sort('total_recovered')}><span className={isSort==="total_recovered" ? "label label-primary" : "" }  >Recovered<small>&nbsp;<br/>data not available</small></span></th>
                                    <th scope="col" onClick = { () => this.toggle_sort('total_deaths')}><span className={isSort==="total_deaths" ? "label label-primary" : "" }  >Deaths</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                
                                    {Object.keys(this.state.covid_data).map(
                                        key =><ListData key={key} 
                                                        index = {key}
                                                        rank = {rank++}
                                                        details={this.state.covid_data[key]}
                                                        single_country = {this.single_country} /> 
                                                        )} 
                
                                </tbody>
                            </table>
                        </div>

                        <div className="col-md-5 first-div">

                        <SingleCountry  
                                        single_country_data = {this.state.single_country_data}
                                        />
                        
                        </div>
                    </div>
                </div>

            </React.Fragment>

            
        )
    }

}


export default COVID19_Index;