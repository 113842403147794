import React from "react";
// import {Line} from 'react-chartjs-2';

// const state = {
//     labels: ['January', 'February', 'March',
//              'April', 'May'],
//     datasets: [
//       {
//         label: 'Rainfall',
//         fill: true,
//         lineTension:0.5,
//         backgroundColor: 'rgba(75,192,192,1)',
//         borderColor: 'rgba(0,0,0,1)',
//         borderWidth: 3,
//         data: [65, 59, 80, 81, 56]
//       }
//     ]
//   }


class SingleCountry extends React.Component{

    render(){

        // const {country, confirmed, recovered, deaths} = this.props.details;
        const country_map_url = "https://maps.googleapis.com/maps/api/staticmap?center="+this.props.single_country_data.latitude+","+this.props.single_country_data.longitude+"&zoom=3&size=300x300&key=AIzaSyCAC1Q1ar1u4PlwjKxvT_2bTfpEYQY-Bbw&markers="+this.props.single_country_data.latitude+","+this.props.single_country_data.longitude+"&scale=2" 



        return (
            <React.Fragment>

                <div className="shadow  bg-white rounded">
                    <div className="card">

                        <div className="card-header">
                            <h5 className="card-title">{this.props.single_country_data.country} </h5>
                           
                            <div className = "row">
                                <div className = "col-md-4 col-sm-4">
                                    <small className="label label-danger"><b>CONFIRMED</b></small><br />
                                    <small><b>Total : </b>  {this.props.single_country_data.total_confirmed}  </small><br />
                                    <small><b> 24 Hours:</b> +{this.props.single_country_data.c_last_24hours}</small><br />
                                    <small><b> 1 Week:</b> +{this.props.single_country_data.c_last_7days}</small><br />
                                    <small><b> 1 Month: </b> +{this.props.single_country_data.c_last_30days}</small>
                                </div>
                                
                                
                                <div className = "col-md-4 col-sm-4">
                                    <small  className="label label-primary"  ><b>RECOVERED</b></small><br />
                                    <small><b>Total: </b> {this.props.single_country_data.total_recovered} </small><br />
                                    <small><b> 24 hours:</b> +{this.props.single_country_data.r_last_24hours}</small><br />
                                    <small><b> 1 Week:</b> +{this.props.single_country_data.r_last_7days}</small><br />
                                    <small><b> 1 Month:</b> +{this.props.single_country_data.r_last_30days}</small>
                            
                                </div>

                                <div className = "col-md-4 col-sm-4">
                                    <small className="label label-secondary"  ><b>DEATHS</b></small><br />
                                    <small><b>Total: </b>{this.props.single_country_data.total_deaths}  </small><br />
                                    <small><b> 24 hours:</b> +{this.props.single_country_data.d_last_24hours}</small><br />
                                    <small><b> 1 Week:</b> +{this.props.single_country_data.d_last_7days}</small><br />
                                    <small><b> 1 Month:</b> +{this.props.single_country_data.d_last_30days}</small>
                                </div>

                                
                            </div>
                        
                        </div>

                        
                        {/* <div className="card-header">
                            <p>CHART</p>

                            <Line
                                data={state}
                                options={{
                                    title:{
                                    display:true,
                                    text:'Average Rainfall per month',
                                    fontSize:20
                                    },
                                    legend:{
                                    display:true,
                                    position:'right'
                                    }
                                }}
                            />
                        </div> */}

                        <div className="card-header">
                            <img className="img-fluid" src={country_map_url}  alt={this.props.single_country_data.country}  />
                        </div>



                    </div>
                </div>


            </React.Fragment>

            
        )
    }

}


export default SingleCountry;