import React from "react";



class ListData extends React.Component{



    render(){

        const {country, total_confirmed, total_recovered, total_deaths} = this.props.details;


        return (
            <React.Fragment>

                <tr onClick={() => this.props.single_country(this.props.details, this.props.index)}> 
                <td>{this.props.rank}</td>
                <td>{country}</td>
                <td>{total_confirmed}</td>
                <td>{total_recovered}</td>
                <td>{total_deaths}</td>
                </tr>

            </React.Fragment>

            
        )
    }

}


export default ListData;